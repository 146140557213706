
.addDelivery-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: 20px;
    .addDelivery-info {
        padding: 0 20px;
        /*height: calc(100vh - 220px);*/
        .addDelivery-title {
            font-size: 20px;
            padding-left: 15px;
            line-height: 1;
            margin: 20px 0 30px;
            display: flex;
            align-items: center;
        }
    }
    ::v-deep .el-form{
        height: 100%;
    }
    .dialog-footer {
        text-align: center;
        margin: 20px 0;
    }
    .form-item{
        display: flex;
        align-items: center;
        .prompt-title{
            color: #999;
            font-size: 16px;
            margin: -24px 10px 0;
        }
    }
    .where-address{
        position: relative;
        &:after{
            content: '*';
            color: #E84932;
            position: absolute;
            top: 12px;
            left: 35px;

        }
    }
}
